#Name,
#Email,
#Message {
  opacity: 70%;
  background-color: rgb(59, 117, 241);
  color: rgb(249, 248, 251);
  font-weight: bold;
}

input.form-control::placeholder,
textarea.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}


#curve {
  padding: 200px 20px 10px 20px;
  background-image: radial-gradient(ellipse 100% 200% at 50% 200%, rgb(0, 0, 0) 90%, #0000 90%),
      radial-gradient(ellipse 100% 200% at 50% 197%, rgb(92, 92, 92) 90%, #0000 90%);
  font-family: sans-serif;
  text-transform: uppercase;
  border-radius: 10px;
} 