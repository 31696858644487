.hero {
  height: 50vh;
  max-width: 100%;
}


.wrapper{
  width: 30%;
  padding: 0;
  margin-left: 35%;
  margin-top: -5%;
}

#fancy-text-hero {
  background-image: 
    linear-gradient(to right, rgb(0, 21, 255), rgb(192, 133, 133),silver, rgb(0, 37, 222)); 
    background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  
  animation: move 2000s linear infinite;
}
@keyframes move {
  to {
    background-position: 4500vh;
  }
  }