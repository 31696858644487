* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

root {
  padding: 0;
  margin: 0;
}

body {
  background-color: #d5d2d2 !important;
  font-family: 'Roboto', sans-serif;
}
