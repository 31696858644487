.wrapper2 {
  width: 25%;
  height: auto;
  margin: 0;
  padding: 0;


}

.img {
  width: 50%;

}