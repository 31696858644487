.hovereffect {
  width: 100%;
  height: inherit;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.hovereffect:hover .overlay {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.hovereffect:hover h2 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 18px;
  padding: 3px;
  background: rgba(0, 0, 0, 0.6);
  animation: bounceInDown; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.75s; /* don't forget to set a duration! */
}

.hovereffect:hover .info {
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  padding: 6px 14px;
  text-transform: uppercase;
  color: aqua;
  border: 1px solid aqua;
  margin: 12% 0 0 0;
  background-color: transparent;
  animation: rotateInUpLeft; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.25s; /* don't forget to set a duration! */
}

.hovereffect .info:hover {
  box-shadow: 0 0 10px #fff;
}

.languages {
  color: aqua;
  font-size: 0.5em;
  text-align: center;
}
